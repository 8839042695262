<template>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card v-if="page" :title="page.name">
          <div v-html="page.body"></div>
        </vx-card>
      </div>

    </div>
</template>

<script>
    export default {
        name: "Page",
        data(){
           return{
             id:this.$route.params.id,
             page:false,
           }
        },
        created(){
          this.$vs.loading();
          this.axios.get('/page/'+this.id).then(({data})=> {
            this.page = data;
            this.$vs.loading.close();
          }).catch(error=>{
            this.$vs.loading.close();
            this.$router.push('/404')
          })
        }
    }
</script>

<style scoped>

</style>
